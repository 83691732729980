import { PORTAL_PATH } from '@libs-components/utils/routes'

/**
 * @deprecate Temporary re-export of PORTAL_PATH from libs-components for capability
 * @description Importing/Adding PORTAL_PATH directly in libs-components in the future
 **/
export const AllPaths = PORTAL_PATH

export const shopPage = `${process.env.REACT_APP_WU_SHOP_DOMAIN}/`
export const shopCartPage = `${process.env.REACT_APP_WU_SHOP_DOMAIN}/cart`
