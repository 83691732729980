import * as React from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'

import { Page } from './helper'

const routesConfig: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: 'trial',
        children: [
          {
            path: 'ai',
            element: <Page filePath='trial/ai' useDeviceBlocker={false} />,
          },
          {
            path: 'question-group',
            element: <Page filePath='trial/question-group' useDeviceBlocker={false} />,
          },

          {
            path: 'exam-paper',
            element: <Page filePath='trial/exam-paper' useDeviceBlocker={false} />,
          },
        ],
      },
    ],
  },
]

const TrialRoutes: React.FC = () => {
  const routes = useRoutes(routesConfig)
  return routes
}

export default TrialRoutes
