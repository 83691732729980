import * as React from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'

import { Page } from './helper'

const routesConfig: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: 'webview-component',
        children: [
          {
            path: 'video',
            element: <Page filePath='webview-component/video' useDeviceBlocker={false} />,
          },
        ],
      },
    ],
  },
]

const WebviewComponentRoutes: React.FC = () => {
  const routes = useRoutes(routesConfig)
  return routes
}

export default WebviewComponentRoutes
