import isEmpty from 'lodash.isempty'

export function appendQueryObject(
  url: string,
  query: { [key: string]: string | null | undefined } = {},
) {
  if (isEmpty(query)) return url

  Object.keys(query).forEach(key => {
    if (query[key] === null || query[key] === undefined) {
      delete query[key]
    }
  })
  if (isEmpty(query)) return url

  const queries = Object.keys(query)
    .reduce((result, key) => result.concat(`${key}=${query[key]}`), [] as string[])
    .join('&')
  return `${url}?${queries}`
}

type isUrlsObjectEmptyProps = Record<string, string> | string[] | undefined | null

export const isUrlsObjectEmpty = (urls: isUrlsObjectEmptyProps): boolean => {
  if (!urls) return true

  if (Array.isArray(urls)) {
    return urls.filter(e => typeof e === 'string' && !!e).length === 0
  }

  return Object.values(urls).filter(e => typeof e === 'string' && !!e).length === 0
}

export const filterImgUrl = (url: string) => {
  if (!url) return ''
  if (url.match('https://public.wordup.com.tw')) {
    return url.replace('https://public.wordup.com.tw', '')
  }
  if (url.match('https://public-staging.wordup.com.tw')) {
    return url.replace('https://public-staging.wordup.com.tw', '')
  }
  return ''
}
export const filterDomain = (url: string) => {
  if (!url) return ''
  if (url.match('https://public.wordup.com.tw')) {
    return 'https://public.wordup.com.tw'
  }
  if (url.match('https://public-staging.wordup.com.tw')) {
    return 'https://public-staging.wordup.com.tw'
  }
}
