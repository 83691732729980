import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import enUS from './locales/en/common.json'
import zhTW from './locales/zh-TW/common.json'
import crowdinEnUs from './locales/en/crowdin.json'
import crowdinZhTW from './locales/zh-TW/crowdin.json'

export const LANGUAGE = {
  TW: 'zh-TW',
  EN: 'en',
} as const

export const resources = {
  [LANGUAGE.EN]: { translation: enUS, crowdin: crowdinEnUs },
  [LANGUAGE.TW]: { translation: zhTW, crowdin: crowdinZhTW },
} as const

export const isLocaleTW = (lang: string) => lang === LANGUAGE.TW
export const isLocaleEN = (lang: string) => lang === LANGUAGE.EN

if (!i18n.isInitialized) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: LANGUAGE.TW,
      resources,
      debug: process.env.NODE_ENV === 'development',
      load: 'languageOnly',
      returnNull: false,
      returnObjects: true,
      defaultNS: 'translation',
      interpolation: {
        escapeValue: false, // React already did
      },
    })
}

export default i18n
