import { createTheme } from '@mui/material/styles'
import { theme } from '@word-up/components'

import TailwindConfig from '@/tailwind.config'

const TailwindColor = TailwindConfig.theme.extend.colors

const customMuiTheme = createTheme({
  ...theme,
  palette: {
    primary: {
      light: TailwindColor['primary-100'],
      main: TailwindColor['primary-500'],
      dark: TailwindColor['primary-900'],
      contrastText: TailwindColor['grayscale-000'],
    },
    secondary: {
      light: TailwindColor['peach-100'],
      main: TailwindColor['peach-500'],
      dark: TailwindColor['peach-900'],
      contrastText: TailwindColor['grayscale-000'],
    },
    info: {
      light: TailwindColor['grayscale-100'],
      main: TailwindColor['grayscale-500'],
      dark: TailwindColor['grayscale-900'],
      contrastText: TailwindColor['grayscale-000'],
    },
    error: {
      light: TailwindColor['peach-100'],
      main: TailwindColor['peach-500'],
      dark: TailwindColor['peach-900'],
      contrastText: TailwindColor['grayscale-000'],
    },
    warning: {
      light: TailwindColor['yellow-100'],
      main: TailwindColor['yellow-500'],
      dark: TailwindColor['yellow-900'],
      contrastText: TailwindColor['grayscale-800'],
    },
    success: {
      light: TailwindColor['green-100'],
      main: TailwindColor['green-500'],
      dark: TailwindColor['green-900'],
      contrastText: TailwindColor['grayscale-000'],
    },
    cancel: {
      light: TailwindColor['grayscale-100'],
      main: TailwindColor['grayscale-500'],
      dark: TailwindColor['grayscale-900'],
      contrastText: TailwindColor['grayscale-000'],
    },
  },
})

export default customMuiTheme
