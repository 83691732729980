import { useAtom } from 'jotai'
import { useIntercom } from 'react-use-intercom' // import library directly to avoid circular import

import useSwrMe from '@/data/use-swr-me'
import CredentialStorage from '@/utils/credential'
import LocalStorageLib from '@/utils/local-storage-lib'
import AuthService from '@/apis/auth'

import { utils } from '@/hooks/use-intercom'
import { meAtom } from '@/store/me'
import cookieStorage from '@/utils/cookie-storage'
import { COOKIE_KEYS, WHITELISTED_COOKIE_KEYS } from '@/utils/cookie-storage/constants'

const useAuth = () => {
  const [user, setAtomUser] = useAtom(meAtom)
  const { mutate, update } = useSwrMe()
  const { update: updateIntercom } = useIntercom()

  const fetchMe = async () => {
    const data = await mutate()
    setAtomUser(data)
    return data
  }

  const logout = async () => {
    await AuthService.signOut()
    Object.keys(COOKIE_KEYS).forEach(key => {
      const currentCookieKey = COOKIE_KEYS[key as keyof typeof COOKIE_KEYS]
      if (!WHITELISTED_COOKIE_KEYS.includes(currentCookieKey)) {
        cookieStorage.del(currentCookieKey)
        cookieStorage.del(currentCookieKey, {
          domain: process.env.REACT_APP_ROOT_DOMAIN!,
        })
      }
    })
    LocalStorageLib.delAll()
    CredentialStorage.clear(() => {
      setAtomUser(undefined)
    })
  }

  const onSignInSuccess = (data: any) => {
    const {
      data: { email, id, name },
    } = data

    setAtomUser(data)
    mutate(data)

    const intercomInfo = { name, email, userId: utils.intercomUserId(id) }
    updateIntercom(intercomInfo)
  }

  const updateInfo = async (data: any, options?: { hideToast: boolean }) => {
    const info = await update(data, options)
    setAtomUser(info)
    return info
  }

  const isSchoolAdmin = !!user?.data?.attributes?.schoolAdmin

  const isWuAdmin = !!(user?.data?.attributes?.wuAdminRole === 'basic')

  return {
    user,
    fetchMe,
    updateInfo,
    onSignInSuccess,
    logout,
    isSchoolAdmin,
    isWuAdmin,
  }
}
export default useAuth
