const plugin = require('tailwindcss/plugin')

module.exports = {
  mode: 'jit',
  purge: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      // MUI default breakpoints
      sm: '600px',
      mobileUp: '768px',
      md: '900px',
      lg: '1200px',
      xl: '1536px',
    },
    extend: {
      transitionProperty: {
        height: 'height',
        spacing: 'margin, padding',
        padding: 'padding',
        minWidth: 'min-width',
        maxWidth: 'max-width',
        maxHeight: 'max-height',
        left: 'left',
        right: 'right',
        top: 'top',
        bottom: 'bottom',
      },
      colors: {
        'green-100': '#daf4de',
        'green-300': '#acebc2',
        'green-500': '#16c172',
        'green-700': '#0a8a69',
        'green-900': '#075a54',
        'peach-100': '#feebda',
        'peach-300': '#fbb291',
        'peach-500': '#f06449',
        'peach-700': '#ac2424',
        'peach-900': '#730e1c',
        'yellow-100': '#fffbcc',
        'yellow-300': '#ffed66',
        'yellow-500': '#ffde00',
        'yellow-700': '#bea917',
        'yellow-900': '#75680d',
        'purple-100': '#f8e9ff',
        'purple-300': '#e5bcff',
        'purple-500': '#c890ff',
        'purple-700': '#7548b8',
        'purple-900': '#381b7a',
        'aqua-100': '#ccf0ed',
        'aqua-300': '#8adce2',
        'aqua-500': '#42badb',
        'aqua-700': '#21709d',
        'aqua-900': '#0c3a6a',
        'coral-100': '#feece2',
        'coral-300': '#fcb9a8',
        'coral-500': '#f8736e',
        'coral-700': '#b23847',
        'coral-900': '#771532',
        'primary-100': '#e2f2ff',
        'primary-200': '#c6e6ff',
        'primary-300': '#abd6ff',
        'primary-400': '#94c7fe',
        'primary-500': '#72afff',
        'primary-600': '#5488db',
        'primary-700': '#3a64b8',
        'primary-800': '#244594',
        'primary-900': '#152f79',
        'grayscale-000': '#fff',
        'grayscale-50': '#F4F5F5',
        'grayscale-100': '#eeefef',
        'grayscale-200': '#dedfdf',
        'grayscale-300': '#bdc0c0',
        'grayscale-400': '#9da1a1',
        'grayscale-500': '#7c8282',
        'grayscale-600': '#5c6363',
        'grayscale-700': '#464d4d',
        'grayscale-800': '#383b3d',
        'grayscale-900': '#2a2e2e',
        'grayscale-1000': '#151717',
        'grayscale-1100': '#0e1010',
        'background-light': '#f6f8fc',
        'background-dark': '#151717',
      },
      fontFamily: {
        noto: ['NotoSansCJKtc-Bold', 'Noto Sans TC', 'sans-serif'],
        mono: ['Noto Sans Mono', 'NotoSansCJKtc-Bold', 'Noto Sans TC', 'sans-serif'],
      },
      height: {
        'fit-content': 'fit-content',
      },
      cursor: {
        grab: 'grab',
        copy: 'copy',
      },
      animation: {
        'reverse-spin': 'reverse-spin 1s linear infinite',
        'fade-in-300': 'fade-in 0.3s ease-in',
        'fade-in-500': 'fade-in 0.5s ease-in',
        'fade-in-700': 'fade-in 0.7s ease-in',
      },
      keyframes: {
        'reverse-spin': {
          from: {
            transform: 'rotate(360deg)',
          },
        },
        'fade-in': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
      backgroundImage: {
        'registration-page':
          'url(https://public.wordup.com.tw/shop/assets/sign+up+page/sign_up.png)',
        'password-page':
          'url(https://public.wordup.com.tw/web-assets/forget-password/bg-tile.png)',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    plugin(({ addUtilities, addBase }) => {
      addUtilities({
        '.fade-right': {
          '-webkit-mask-image': 'linear-gradient(90deg, #000 90%, transparent)',
        },
        '.bottom-left-border': {
          borderLeft: '1px solid #d8d8d8',
          borderImage: 'linear-gradient(to top, #d8d8d8 50%, transparent 50%) 0% 1;',
        },
        '.top-left-border': {
          borderLeft: '1px solid #d8d8d8',
          borderImage: 'linear-gradient(to bottom, #d8d8d8 50%, transparent 50%) 0% 1;',
        },
        '.left-border': {
          borderLeft: '1px solid #d8d8d8',
        },
        '.hide-scrollbar': {
          '&::-webkit-scrollbar': 'display: none',
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
        '.break-word': {
          wordBreak: 'break-word',
        },
        '.clamp-line-5': {
          display: '-webkit-box',
          '-webkit-line-clamp': '5',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
        },
        '.clamp-line-2': {
          display: '-webkit-box',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
        },
        '.hyphens-auto': {
          hyphens: 'auto',
        },
        '.shadow-custom': {
          'box-shadow': '0 0 10px 0 rgba(0,0,0,0.1)',
        },
        '.text-ellipsis': {
          textOverflow: 'ellipsis',
        },
        '.aspect-video': {
          aspectRatio: '16/9',
        },
        '.aspect-square': {
          aspectRatio: '1/1',
        },
        '.custom-icon': {
          fontFamily: 'custom-icon !important',
          fontStyle: 'normal',
        },
        '.custom-shadow': {
          boxShadow: '0px 0px 10px 0px #0000001A',
        },
      })
      addBase({
        '.scrollbar::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '.scrollbar::-webkit-scrollbar': {
          height: '8px',
          width: '8px',
        },
        '.scrollbar::-webkit-scrollbar-thumb': {
          backgroundColor: '#eeefef',
          borderRadius: '16px',
          transition: 'background-color 5s ease-in-out',
        },
        '.scrollbar::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#dedfdf',
        },
        '.scrollbar::-webkit-scrollbar-track-piece': {
          background: 'transparent',
        },
      })
    }),
  ],
}
