import { AxiosResponse } from 'axios'

import * as ApiTypes from '@/typings/api'
import fire from '../fire'

const authService = {
  signUp: (payload: ApiTypes.SignUpPayload) =>
    fire({ method: 'post', url: '/auth', data: { ...payload } }) as Promise<any>,
  signUpViaFb: (res: any) => {
    if (!res || !res.accessToken) {
      return Promise.reject(
        '若要使用 Facebook 登入，請您同意開放權限。我們僅使用 Email 及使用者名稱用以申請帳號。\n或請使用一般 Email 註冊或登入，謝謝。',
      )
    }

    return fire({
      method: 'post',
      url: '/facebooks',
      data: {
        access_token: res.accessToken,
      },
    })
  },
  signUpViaGoogle: (res: any) => {
    if (!res || !res.tokenId) {
      return Promise.reject(
        '若要使用 Google 登入，請您同意開放權限。我們僅使用 Email 及使用者名稱用以申請帳號。\n或請使用一般 Email 註冊或登入，謝謝。',
      )
    }

    return fire({
      method: 'post',
      url: '/third_parties/google/signin_with_google',
      data: {
        user_info: {
          email: res.profileObj.email,
          id_token: res.tokenId,
        },
      },
    })
  },
  signIn: (payload: ApiTypes.SignInPayload) =>
    fire({ method: 'post', url: '/auth/sign_in', data: { ...payload } }),
  signInViaSanmin: ({ token }: { token: string }) =>
    fire({
      method: 'post',
      url: '/third_parties/sanmin/signin_with_sanmin',
      data: {
        user_info: {
          token: encodeURI(token),
        },
      },
    }),
  signOut: () => fire({ method: 'delete', url: '/auth/sign_out' }),
  forgetPassword: (payload: ApiTypes.ForgetPasswordPayload) =>
    fire({ method: 'post', url: '/auth/password', data: { ...payload } }),
  getUserInfo: () =>
    fire({ method: 'get', url: '/me' }) as Promise<
      AxiosResponse<ApiTypes.GetUserInfoResponse>
    >,
  deleteUserAccount: ({ reason }: { reason: string }) =>
    fire({
      method: 'delete',
      url: '/me',
      data: {
        reason: reason,
      },
    }),
}

export default authService
