import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Modal, ModalProps } from '@mui/material'
import { twMerge } from 'tailwind-merge'

export interface WuModalProps extends ModalProps {
  hideCloseButton?: boolean
  padding?: string
  maxWidth?: string
}

const WuModal = (props: WuModalProps) => {
  const { hideCloseButton, padding, maxWidth, ...restProps } = props

  return (
    <Modal {...restProps}>
      <div
        className={twMerge(
          'absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2 w-full p-4',
          maxWidth ? maxWidth : 'max-w-[48rem]',
        )}
      >
        <div className='relative w-full h-full'>
          <div
            className={`shadow rounded-lg bg-grayscale-000 w-full overflow-hidden ${
              padding ? padding : 'p-[1.875rem]'
            }`}
          >
            {restProps.children}
          </div>
          {!hideCloseButton && (
            <IconButton
              className='w-6 h-6 absolute top-[-1.875rem] right-0'
              onClick={e => {
                restProps.onClose && restProps.onClose(e, 'backdropClick')
              }}
            >
              <CloseIcon className='text-base text-grayscale-000' />
            </IconButton>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default WuModal
