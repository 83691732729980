import { DefaultTheme } from 'styled-components'
import TailwindConfig from '@/tailwind.config'

const theme: DefaultTheme = {
  rwd: {
    phone: '600px',
    overPhone: '601px',
    tablet: '900px',
  },
  font: {
    roboto: "'Roboto', sans-serif",
    noto: "'Noto Sans TC', sans-serif",
    robotoRegular: "'Roboto-Regular', sans-serif",
  },
  fontSize: {
    xxl: '28px',
    xl: '24px',
    l: '20px',
    ml: '18px',
    m: '16px',
    sm: '14px',
    s: '12px',
    xs: '10px',
    xxs: '8px',
  },
  color: TailwindConfig.theme.extend.colors,
  rounded: {
    s: '4px',
    m: '8px',
    l: '12px',
    xl: '16px',
  },
  distance: {
    xs: '4px',
    s: '8px',
    sm: '12px',
    m: '16px',
    ml: '20px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
  },
  device: {
    s: '(min-width: 600px)',
    m: '(min-width: 900px)',
    l: '(min-width: 1200px)',
    mobileOnly: '(max-width: 599px)',
  },
  spacing: {
    xs: '5px',
    s: '15px',
    m: '30px',
    l: '45px',
    xl: '60px',
  },
  shadow: {
    card: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    card_m: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
    toggle_btn: '0 0 10px 0 rgba(0, 0, 0, 0.35)',
  },
}

export default theme
