import { useAtom } from 'jotai'
import { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { useTranslation } from 'react-i18next'

import PageLoader from '@/app/components/page-loader'
import LoadingScreen from '@/components/screen/loading'
import Swal from '@/utils/sweetalert'

import useAuth from '@/hooks/use-auth'
import useThirdPartyLogin from '@/hooks/use-third-party-login.ts'
import { isLoadingAtom } from '@/store/global'
import TrialRoutes from '@/routes/trial-routes'
import WebviewComponentRoutes from '@/routes/webview-component-routes'

const AuthenticatedApp = lazy(() => import('./authenticated-app'))
const UnAuthenticatedApp = lazy(() => import('./unauthenticated-app'))

declare global {
  interface Window {
    opera: any
  }
}

const App = () => {
  const isTrialPage = /\/trial\/.+$/.test(window.location.pathname)
  const isWebviewComponentPage = /\/webview-component\/.+$/.test(window.location.pathname)

  const { t } = useTranslation()
  const { user, fetchMe } = useAuth()
  const [isLoading] = useAtom(isLoadingAtom)
  const [isReady, setIsReady] = useState<Boolean>(false)

  const { thirdPartyLogin } = useThirdPartyLogin()

  useEffect(() => {
    const setup = async () => {
      const doesNotRequireLogin = isTrialPage || isWebviewComponentPage

      if (doesNotRequireLogin) {
        setIsReady(true)
        return
      }

      await thirdPartyLogin()
      await fetchMe()

      setIsReady(true)
    }

    setup()
  }, [])

  if (isTrialPage) {
    return (
      <BrowserRouter>
        <TrialRoutes />
      </BrowserRouter>
    )
  }

  if (isWebviewComponentPage) {
    return (
      <BrowserRouter>
        <WebviewComponentRoutes />
      </BrowserRouter>
    )
  }

  if (!isReady) {
    return <LoadingScreen />
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <SWRConfig
        value={{
          onErrorRetry: async (err, _key, _config, revalidate, { retryCount }) => {
            if (retryCount >= 3) {
              if (err.response.status === 404) {
                window.location.href = '/404'
                return
              }
              if (err.response.status === 403) {
                await Swal.fire({
                  title: t('empty.no_teacher_access.title'),
                  text: t('empty.no_teacher_access.popup_desc'),
                  icon: 'warning',
                })
                window.history.back()
                return
              }
            }
            setTimeout(() => revalidate({ retryCount }), 1000)
          },
          shouldRetryOnError: true,
          revalidateOnMount: true,
        }}
      >
        <PageLoader isLoading={isLoading} />
        <BrowserRouter>
          {user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
        </BrowserRouter>
      </SWRConfig>
    </Suspense>
  )
}

export default App
