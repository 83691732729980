import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const PageLoader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: 999999 }} open={isLoading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export default PageLoader
