import { pathToRegexp } from 'path-to-regexp'
import REDIRECT_TO_CRA_PORTAL_PATHS from './redirect-to-cra-portal-paths.json'

/**
 * @description The paths handled by CRA portal
 **/
const REGEX_CRA_PORTAL_HANDLED_PATHS = REDIRECT_TO_CRA_PORTAL_PATHS.map(
  (path) => pathToRegexp(path, [], { sensitive: true, strict: true })
)

/**
 * @description Checks the toggle status to determine if redirect is allowed, as defined in the Strapi API.
 **/
const checkIsAllowedRedirectToNextJsPortal = async (
  strapiApiDomain: string
) => {
  if (!strapiApiDomain) return false

  try {
    const response = await fetch(
      `${strapiApiDomain}/api/site-configs?filters[key]=toggle-portal-migration-redirect`
    )

    if (!response.ok) throw new Error('strapi fetch error')

    const { data } = await response.json()

    return data[0].attributes.value_boolean
  } catch {
    return false
  }
}

/**
 * @param nextJsPortalDomain - The domain of the Next.js portal
 * @param strapiDomain - The domain of the Strapi API
 * @returns is redirect to Next.js portal
 * @description Redirects to the Next.js portal if the path is not handled by the CRA portal.
 * Redirect is checked only when both domains exist; otherwise, redirect is disabled.
 **/
export const redirectToNextJsPortalIfNeeds = async (
  nextJsPortalDomain: string,
  strapiDomain: string
) => {
  if (!nextJsPortalDomain) return
  if (!(await checkIsAllowedRedirectToNextJsPortal(strapiDomain))) {
    return
  }

  const { pathname, search } = window.location
  const isPathHandedByCRAPortal = REGEX_CRA_PORTAL_HANDLED_PATHS.some(
    (handedPathRegex) => handedPathRegex.test(pathname)
  )

  if (isPathHandedByCRAPortal) return

  window.location.href = new URL(
    `${nextJsPortalDomain}${pathname}${search}`
  ).toString()
  return true
}
