import axios, { AxiosRequestConfig } from 'axios'
import { getI18n } from 'react-i18next'

import { GENERIC_API_ERR_MESSAGE } from '@/constants/message'
import CredentialStorage from '@/utils/credential'
import { notify } from '@/utils/error-notifier'
import Swal from '@/utils/sweetalert'

export const clientInfo = `web2/${process.env.REACT_APP_VERSION}`

export type FireConfigType = AxiosRequestConfig & {
  errorContext?: { [key: string]: any }
  ignoreThrowError?: boolean
  errorToastMessage?: string
}

const onResponseError = async (error: any) => {
  const errorContext = error?.config?.errorContext
  const isValidErrorContext =
    Boolean(errorContext) &&
    typeof errorContext === 'object' &&
    !Array.isArray(errorContext)!
  const shouldThrowError = !Boolean(error?.config?.ignoreThrowError)
  const errorToastMessage = error?.config?.errorToastMessage

  if (error.response?.status === 500) {
    Swal.fire({
      icon: 'warning',
      text: GENERIC_API_ERR_MESSAGE,
      confirmButtonText: '好',
    })
  }

  notify({
    err: error,
    context: {
      key: 'response error',
      ...(isValidErrorContext ? errorContext : {}),
    },
    toast: errorToastMessage
      ? {
          type: 'error',
          props: {
            message: errorToastMessage,
          },
        }
      : undefined,
  })

  if (shouldThrowError) {
    return Promise.reject(error)
  }

  return
}

const onResponse = (response: any) => {
  CredentialStorage.update(response)

  return response
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  headers: {
    'Client-Info': clientInfo,
  },
})

axiosInstance.interceptors.response.use(onResponse, onResponseError)

const fire = (config: FireConfigType) => {
  const headers = {
    'Content-Type': 'application/json',
    'Client-Lang': getI18n().language,
    ...CredentialStorage.get(),
    ...(config.headers || {}),
  }

  return axiosInstance.request({ ...config, headers })
}

export default fire
