export const COOKIE_KEYS = {
  HAVE_SEEN_USE_APP_SUGGESTION: 'have_seen_use_app_suggestion',
  HIDE_DOWNLOAD_APP_SUGGESTION: 'download_app_suggestion_hide',
  CHOSEN_HOME_PAGE_TAB: 'chosen_home_page_tab',
  SHOP_CART: 'cart',
  USER_MOBILE: 'user_mobile',
  AGENT_ORG_ID: 'agent_org_id',
  AUDIO_PLAY_RATE: 'audio_play_rate',
  DECK_SORT_TYPE: 'deck_sort_type',
  CHECKLIST_CALCULATION: 'checklist_calculation',
  SUBSCRIPTION_TRIAL_NEAR_PRACTICE_LIMIT:
    'subscription_trial_near_practice_limit',
  SUBSCRIPTION_SHOW_FEEDBACK_MODAL: 'subscription_show_feedback_modal',
  IS_GDPR_MODAL_CONFIRMED: 'is_gdpr_modal_confirmed',
  CREATE_CARD_SUCCESS_MESSAGE: 'create_card_success_message',
  TOTAL_CARDS_CREATED: 'total_cards_created',
  LAST_CREATED_DECK_ID: 'last_created_deck_id',
  CREATE_CARD_REMINDER_SHOWN: 'create_card_reminder_shown',
  LAST_SELECTED_ORG_ID: 'last_selected_org_id',
}
