import { isProd } from '@/utils/env'
import * as Sentry from '@sentry/react'
import React from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: isProd() ? 0.1 : 1.0,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/

      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    ignoreErrors: [
      /Network\s+Error/i,
      'ResizeObserver loop limit exceeded',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Request aborted',
      'ChunkLoadError',
      'DOMException: play() failed',
      /NotSupportedError: Failed/i,
      /The request is not allowed by the user agent/i,
      /NotAllowedError: play() failed/i,
      /AbortError: The play() request was interrupted by a call to pause()/i,
    ],
    environment: process.env.REACT_APP_STAGE,
  })
}

export default initSentry
