import { useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import cookieStorage from '@/utils/cookie-storage'
import { COOKIE_KEYS } from '@/utils/cookie-storage/constants'
import { isProd } from '@/utils/env'
import Swal from '@/utils/sweetalert'
import { BRANCH_IO_APP_LINK } from './constants'

import WuModal from '@/components/modal'

const DeviceUsageBlocker = () => {
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery('(max-width: 768px)')

  const popSuggestion = async () => {
    if (!cookieStorage.get(COOKIE_KEYS.HAVE_SEEN_USE_APP_SUGGESTION)) {
      const res = await Swal.fire({
        title: t('small_width_popup'),
        icon: 'warning',
        confirmButtonText: t('common.confirm'),
      })

      if (res.isConfirmed) {
        cookieStorage.set({
          key: COOKIE_KEYS.HAVE_SEEN_USE_APP_SUGGESTION,
          value: 'true',
          expires: 1,
          domain: '',
        })
      }
    }
  }

  useEffect(() => {
    if (!isMobile && isSmallScreen) {
      popSuggestion()
    }
  }, [isSmallScreen])

  if (isMobile) {
    return (
      <>
        <WuModal open={isMobile && !isTablet} hideCloseButton={true}>
          <div className='flex flex-col items-center justify-center w-full gap-y-4'>
            <div className='text-xl text-center'>{t('app_suggestion_modal_text')}</div>
            <a
              href={isProd() ? BRANCH_IO_APP_LINK.PRODUCTION : BRANCH_IO_APP_LINK.STAGING}
              className='bg-primary-500 text-grayscale-000   text-sm font-medium gap-x-2 flex items-center justify-center py-2 px-4 rounded-lg'
            >
              {t('common.confirm')}
            </a>
          </div>
        </WuModal>
      </>
    )
  }

  return null
}

export default DeviceUsageBlocker
