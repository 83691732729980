import store from 'store'

const set = ({ key, value }: { key: string; value: any }) => store.set(key, value)

const get = ({ key }: { key: string }) => store.get(key)

const del = ({ key }: { key: string }) => store.remove(key)

const delAll = (cb?: () => void) => {
  store.clearAll()

  if (typeof cb === 'function') {
    setTimeout(() => {
      cb()
    }, 500)
  }
}
const utils = { set, get, del, delAll }
export default utils
