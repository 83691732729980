import * as React from 'react'

import CachedIcon from '@mui/icons-material/Cached'

const LoadingScreen: React.FC = () => {
  return (
    <div className='w-full h-screen flex items-center justify-center'>
      <div className='w-14 h-14 p-2 rounded-md shadow flex items-center justify-center text-center'>
        <CachedIcon className='animate-reverse-spin text-3xl' />
      </div>
    </div>
  )
}

export default LoadingScreen
