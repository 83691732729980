import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import ReactDOM from 'react-dom'
import Favicon from 'react-favicon'
import { IntercomProvider } from 'react-use-intercom'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/react'

import customMuiTheme from '@/assets/mui-theme'
import styledTheme from '@/assets/styled-theme'
import WuToastContainer from '@/components/toast/toast-container'
// import { GDPRModal } from '@/components/modal/variant/GDPR'
import './i18n'

import App from './app'

import 'react-toastify/dist/ReactToastify.css'
import 'react-virtualized/styles.css'
import './index.css'
import '@word-up/components/dist/components.cjs.production.min.css'
import initSentry from './utils/error-notifier/initSentry'

initSentry()

const rootNode = document.getElementById('root')

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={styledTheme}>
        <MuiThemeProvider theme={customMuiTheme}>
          <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM_APP_ID!}
            initializeDelay={2000}
          >
            <Favicon url='https://public.wordup.com.tw/favicon.png' />
            <App />
          </IntercomProvider>
          <WuToastContainer />
        </MuiThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
    {/* <GDPRModal /> */}
  </Sentry.ErrorBoundary>,
  rootNode,
)
