import { ToastContent } from 'react-toastify'

const MessageComp = ({
  message,
  actionComponent,
}: {
  message: ToastContent
  actionComponent?: React.ReactNode
}) => {
  return (
    <div className='text-grayscale-800 font-medium leading-4 text-xs flex justify-between items-center'>
      {message}
      {actionComponent}
    </div>
  )
}

export default MessageComp
