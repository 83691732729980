import AuthService from '@/apis/auth'
import { toHome } from '@/routes/helper'
import ErrorNotifier, { isNotifiableError } from '@/utils/error-notifier'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import toast from '@/components/toast'

const useThirdPartyLogin = () => {
  const { t } = useTranslation()

  const thirdPartyLogin = async () => {
    let params = new URL(document.location.toString()).searchParams
    let provider = params.get('provider')
    let accessToken = params.get('accessToken')

    const removeQueries = () => {
      document.location.replace(toHome())
    }

    switch (provider) {
      case 'sanmin': {
        if (!accessToken) return

        try {
          const res = await AuthService.signInViaSanmin({ token: accessToken })
          if (res.status === 200) {
            removeQueries()
          } else {
            throw new Error('Sanmin sign in failed')
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            toast.error({
              message: err?.response?.data?.errors?.[0]?.detail ?? t('err.happens'),
            })
          }

          if (isNotifiableError(err)) {
            ErrorNotifier.notify({
              err,
              context: {
                key: 'Sanmin sign in failed',
                provider: provider,
              },
            })
          }
        }

        break
      }

      default:
        break
    }
  }

  return {
    thirdPartyLogin,
  }
}

export default useThirdPartyLogin
