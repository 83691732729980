import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

import useAuth from '@/hooks/use-auth'
import * as utilsFns from './utils'

export const utils = utilsFns

interface Props {
  allowGuest?: boolean
}

const useWuIntercom = ({ allowGuest = false }: Partial<Props> = {}) => {
  const intercomExports = useIntercom()
  const { user } = useAuth()

  useEffect(() => {
    const { data: userData } = user || {}
    if (!allowGuest && (!userData || !userData?.id)) return

    const {
      id: userId,
      attributes: { name, email },
    } = userData || { attributes: {} };

    intercomExports.boot({
      userId: utilsFns.intercomUserId(userId as string),
      name,
      email,
    })
  }, [user])

  return intercomExports
}

export default useWuIntercom
